// Docs.tsx

import React, { useState, useMemo, useEffect, useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { TransitionGroup, Transition } from 'react-transition-group';
import Section from '../../organisms/Section';
import { useData } from '../../../contexts/DataContext';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import Typography from '../../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../../atoms/Typography.autogen';
import MarkdownBlock from '../../organisms/MarkdownBlock';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import {
  DocInfoWrapper,
  DocPreviewWrapper,
  DocsGalleryWrapper,
  ExpandableOnHover,
  MarkdownBlockWrapper,
  SectionWrapper,
  Spacer,
  StyledTileWrapper,
  TagWrapper,
} from './Docs.styles';
import NavArrow from '../../molecules/NavArrow';
import DocFilters from '../../molecules/DocFilters';
import { useOptimizer } from '../../../contexts/OptimizerContext';
import { useGuide } from '../../../contexts/GuideContext';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';

interface Doc {
  doc_name: string;
  doc_desc: string;
  doc_type: string;
  docs_md: string;
  importance_factor: number;
}

const Docs: React.FC = () => {
  const { data, loading, error } = useData('docs');
  const { activeSection } = useActiveSection();
  const [docs, setDocs] = useState<Doc[]>([]);
  const [isGalleryExpanded, setIsGalleryExpanded] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [filteredDocs, setFilteredDocs] = useState<Doc[]>([]);
  const [activeFilters, setActiveFilters] = useState<string[]>(['all']);
  const [selectedDoc, setSelectedDoc] = useState<Doc | null>(null);
  const { performanceLevel } = useOptimizer();
  const { setGuideMessage, fadeOutGuide } = useGuide();

  const galleryRef = useRef<HTMLDivElement | null>(null);
  const previewRef = useRef<HTMLDivElement | null>(null);
  const markdownBlockRef = useRef<HTMLDivElement | null>(null);

  // Ensure docs are fetched and set
  useEffect(() => {
    if (data) {
      setDocs(data);
    }
  }, [data]);

  const sortedDocs = useMemo(() => {
    return [...docs].sort((a, b) => b.importance_factor - a.importance_factor);
  }, [docs]);

  // Generate the filter list with 'all' and ensure 'project' comes after 'all'
  const FilterList = useMemo(() => {
    const allTypes = Array.from(new Set(sortedDocs.map((doc) => doc.doc_type)));
    const projectIndex = allTypes.indexOf('project');
    if (projectIndex !== -1) {
      allTypes.splice(projectIndex, 1);
      return ['all', 'project', ...allTypes];
    }
    return ['all', ...allTypes];
  }, [sortedDocs]);

  // Update filteredDocs whenever activeFilters or docs change
  useEffect(() => {
    const newFilteredDocs = activeFilters.includes('all') ? sortedDocs : sortedDocs.filter((doc) => activeFilters.includes(doc.doc_type));

    setFilteredDocs(newFilteredDocs);
    setSelectedIndex(0);
    setSelectedDoc(newFilteredDocs[0] || null);
  }, [activeFilters, sortedDocs]);

  // Entry animation for first load and resize animations for expanding/collapsing
  useLayoutEffect(() => {
    if (galleryRef.current && previewRef.current) {
      const galleryItems = galleryRef.current.querySelectorAll('.doc-info-wrapper');
      const navArrow = document.querySelector('.nav-arrow');

      if (activeSection === 'DOCS') {
        // Initially hide elements if not already hidden
        gsap.set([galleryItems, previewRef.current, navArrow], { autoAlpha: 0 });

        if (performanceLevel === 'low') {
          // For low performance, just set the elements to be visible without animations
          gsap.set(galleryItems, { autoAlpha: 1, scaleY: 1, height: 'auto', opacity: 1 });
          gsap.set(previewRef.current, { autoAlpha: 1, scale: 1, x: '0%', height: 'auto' });
          gsap.set(navArrow, { autoAlpha: 1, x: '0%' });
        } else {
          // Animate in the docs content
          gsap.to(previewRef.current, {
            duration: 1.8,
            height: 'auto',
            autoAlpha: 1,
            scale: 1,
            x: '0%',
            ease: 'power2.out',
          });
          gsap.to(galleryItems, {
            duration: 1.8,
            autoAlpha: 1,
            scaleY: 1,
            height: 'auto',
            y: '0%',
            stagger: 0.1,
            ease: 'power2.out',
            clearProps: 'height',
          });
          gsap.to(navArrow, {
            duration: 1.8,
            autoAlpha: 1,
            x: '0%',
            ease: 'power2.out',
          });
        }
      } else {
        // Hide elements immediately if not already hidden
        gsap.set([galleryItems, previewRef.current, navArrow], { autoAlpha: 0 });
      }
    }
  }, [activeSection, performanceLevel]);

  useLayoutEffect(() => {
    if (galleryRef.current && previewRef.current && activeSection === 'DOCS') {
      if (isGalleryExpanded) {
        // Expand gallery and shift preview back to its original position
        gsap.to(previewRef.current, {
          width: 'calc(100%)',
          marginLeft: '0px',
          duration: 0.5,
          ease: 'power2.out',
        });
        gsap.to(galleryRef.current, {
          x: '0%',
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
          delay: 0.2,
        });
      } else {
        gsap.to(previewRef.current, {
          width: '140%',
          marginLeft: '-150px',
          duration: 0.5,
          ease: 'power2.out',
          delay: 0.2,
        });
        // Collapse gallery and center the preview
        gsap.to(galleryRef.current, {
          x: 1,
          width: '0%',
          opacity: 0,
          duration: 0.5,
          ease: 'power2.out',
        });
      }
    }
  }, [isGalleryExpanded, activeSection]);

  const handleHighlight = (index: number) => {
    if (selectedIndex === index) {
      return HighlightLevel.active;
    }
  };

  const handleFilter = (filter: string) => {
    setActiveFilters((prevFilters) => {
      if (filter === 'all') {
        return ['all'];
      } else {
        const isFilterActive = prevFilters.includes(filter);
        let newFilters = isFilterActive ? prevFilters.filter((f) => f !== filter) : [...prevFilters.filter((f) => f !== 'all'), filter];

        if (newFilters.length === 0) {
          newFilters = ['all'];
        }

        return newFilters;
      }
    });
  };

  const handleSelectDoc = (doc: Doc, index: number) => {
    setSelectedDoc(doc);
    setSelectedIndex(index);
  };

  const handleNavArrowHover = () => {
    setGuideMessage('Enter zen mode to focus on the document');
    setTimeout(() => {
      fadeOutGuide();
    }, 4500);
  };

  if (loading) {
    return <LoadingPage isFullPage={false} isLoading={loading} />;
  }

  if (error) {
    return <ErrorPage isFullPage={false} sourceOfError="docs" />;
  }

  const DocItem = ({ doc }: { doc: Doc }) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const index = filteredDocs.findIndex((d) => d.doc_name === doc.doc_name);


    const isSelected = selectedIndex === index;
    const highlightLevel = handleHighlight(index);

    return (
      <DocInfoWrapper ref={itemRef} onClick={() => handleSelectDoc(doc, index)} className="doc-info-wrapper hoverable">
        <StyledTileWrapper is_row_section_active={isSelected}>
          <Typography variant={TypographyVariant.sectionHeader} highlight_level={highlightLevel}>
            {doc.doc_name}
          </Typography>
          <TagWrapper isActive={false} ishovered={false}>
            <Typography variant={TypographyVariant.sectionDescription} highlight_level={highlightLevel}>
              {doc.doc_type}
            </Typography>
          </TagWrapper>
          <ExpandableOnHover>
            <Typography variant={TypographyVariant.sectionDescription} highlight_level={highlightLevel}>
              {doc.doc_desc}
            </Typography>
          </ExpandableOnHover>
        </StyledTileWrapper>
      </DocInfoWrapper>
    );
  };

  return (
    <Section title="Docs">
      <SectionWrapper style={{ display: activeSection === 'DOCS' ? 'flex' : 'none' }}>
        <div className="nav-arrow">
          <NavArrow
            mounted={galleryRef.current !== null ? true : false}
            isFlipped={isGalleryExpanded ? true : false}
            onClick={() => setIsGalleryExpanded(!isGalleryExpanded)}
            onMouseEnter={handleNavArrowHover}
          />
        </div>
        <DocsGalleryWrapper ref={galleryRef}>
          <Spacer />
          <TransitionGroup component={null}>
            {filteredDocs.map((doc) => (
              <Transition
                key={doc.doc_name}
                timeout={500}
                mountOnEnter
                unmountOnExit
                onEnter={(node: gsap.TweenTarget) => {
                  gsap.fromTo(
                    node,
                    { height: 0, opacity: 0 },
                    {
                      height: 'auto',
                      opacity: 1,
                      duration: 0.5,
                      ease: 'power2.out',
                      clearProps: 'height',
                    },
                  );
                }}
                onExit={(node) => {
                  gsap.to(node, {
                    height: 0,
                    opacity: 0,
                    duration: 0.5,
                    ease: 'power2.in',
                  });
                }}
              >
                <DocItem doc={doc} />
              </Transition>
            ))}
          </TransitionGroup>
          <Spacer />
        </DocsGalleryWrapper>

        {/* Document Preview Section */}
        <DocPreviewWrapper ref={previewRef}>
          <DocFilters FilterList={FilterList} activeFilters={activeFilters} handleFilter={handleFilter} hideFilters={!isGalleryExpanded} />
          <MarkdownBlockWrapper ref={markdownBlockRef}>{selectedDoc && <MarkdownBlock extendedpadding markDownContent={selectedDoc.docs_md} />}</MarkdownBlockWrapper>
        </DocPreviewWrapper>
      </SectionWrapper>
    </Section>
  );
};

export default Docs;
