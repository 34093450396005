import React, { useEffect, useLayoutEffect } from 'react';
import Section from '../../organisms/Section';
import NameAndArray from '../../organisms/NameAndArray';
import { Image, RingsWrapper, IAmWrapper, RyjWrapper, StyledSection, LetsGoWrapper } from './Welcome.styles';
import LetsGo from '../../organisms/LetsGo';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import { useGuide } from '../../../contexts/GuideContext';
import { useLoading } from '../../../contexts/LoadingContext'; // Import the context
import { useData } from '../../../contexts/DataContext';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import usePersonalData from '../../../hooks/usePersonalData';

const Welcome: React.FC = () => {
  // active section
  const { activeSection } = useActiveSection();
  const { setGuideMessage } = useGuide();
  const { setLoadingContext } = useLoading();
  const { my_face_image_url } = usePersonalData();
  const { loading, error } = useData('personal');

  useEffect(() => {
    setLoadingContext(true); // Set loading to true before heavy loads
    const images = [my_face_image_url]; // Add other heavy images here
    let loadedCount = 0;

    const handleImageLoad = () => {
      loadedCount += 1;
      if (loadedCount === images.length) {
        setLoadingContext(false); // Set loading to false when all images are loaded
      }
    };

    images.forEach((src) => {
      const img = new window.Image(); // Use window.Image to avoid conflicts
      img.src = src;
      img.addEventListener('load', handleImageLoad);
      if (img.complete) {
        handleImageLoad();
      }
    });
  }, [setLoadingContext]);

  useLayoutEffect(() => {
    if (activeSection === 'PERSONAL') {
      setGuideMessage('You can scroll down to see more of the website! Also, you can use Space, Shift + Space, WASD, ← →, and ↑ ↓ to move around.', 5000);
    }
    return () => setGuideMessage('');
  }, [activeSection]);

  if (loading && !error) {
    return <LoadingPage isLoading={loading} isWelcomePage />;
  }
  if (error) {
    return <ErrorPage sourceOfError="personal" />;
  }

  if (!loading || !error) {
    return (
      <StyledSection>
        <RingsWrapper></RingsWrapper>
        <IAmWrapper>
          <NameAndArray />
        </IAmWrapper>
        <RyjWrapper>
          <Image src={my_face_image_url} alt="My face" />
        </RyjWrapper>
        <LetsGoWrapper>
          <LetsGo />
        </LetsGoWrapper>
      </StyledSection>
    );
    }
  else {
    return <Section title="PERSONAL">
      <LoadingPage isLoading={loading} isWelcomePage />
    </Section>

  }
  }

export default Welcome;
